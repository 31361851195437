<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { getTranslatedProperty } from "@shopware/helpers";

const { t } = useI18n();
const { isLoggedIn, isGuestSession } = useUser();
const { getCountries } = useCountries();
const { getSalutations } = useSalutations();

interface AddressRules {
  salutationId: {};
  company: {};
  email: {};
  firstName: {};
  lastName: {};
  street: {};
  zipcode: {};
  city: {};
  countryId: {};
  phoneNumber: {};
}

const props = defineProps<{
  hasEmail: boolean;
  state: {
    id?: undefined;
    salutationId: string;
    company: string;
    email: string;
    firstName: string;
    lastName: string;
    street: string;
    zipcode: string;
    city: string;
    countryId: string;
    phoneNumber: string;
  };
  rules: AddressRules;
  addressType?: string;
}>();

// Rules
const stateRules = reactive({
  ...props.rules,
});
const validator = useVuelidate(stateRules, props.state);
</script>

<template>
  <!-- Anrede -->
  <div v-if="!isLoggedIn && !isGuestSession">
    <label
      for="selectSalutaion"
      class="text-scheppach-primary-500 text-sm md:text-base mb--3"
    >
      {{ t("form.salutation") }} *
    </label>
    <select
      id="selectSalutaion"
      v-model="props.state.salutationId"
      required
      class="sw-search-input placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b mb-5"
      :class="[
        validator.salutationId.$error
          ? 'b-scheppach-error-500'
          : 'b-scheppach-primary-700',
      ]"
      @blur="validator.salutationId.$touch()"
    >
      <option disabled selected value="">
        {{ t("form.chooseSalutation") }}
      </option>
      <option
        v-for="salutation in getSalutations"
        :key="salutation.id"
        :value="salutation.id"
      >
        {{ getTranslatedProperty(salutation, "displayName") }}
      </option>
    </select>
  </div>

  <!-- Business -->
  <ScheppachTextInput
    v-if="props.addressType == 'customerBusiness'"
    v-model="props.state.company"
    :margin="3"
    :label="t('form.business')"
    :placeholder="t('form.businessPlaceholder')"
    autocomplete="organization"
  />

  <!-- First Name -->
  <ScheppachTextInput
    v-model="props.state.firstName"
    :margin="3"
    :label="t('form.firstName')"
    :required="true"
    :input-error="validator.firstName?.$error"
    :input-error-message="validator.firstName?.$errors[0]?.$message"
    :placeholder="t('form.firstNamePlaceholder')"
    autocomplete="given-name"
    @blur="validator.firstName?.$touch()"
  />

  <!-- Last Name -->
  <ScheppachTextInput
    v-model="props.state.lastName"
    :label="t('form.lastName')"
    :margin="3"
    :required="true"
    :input-error="validator.lastName?.$error"
    :input-error-message="validator.lastName?.$errors[0]?.$message"
    :placeholder="t('form.lastNamePlaceholder')"
    autocomplete="family-name"
    @blur="validator.lastName?.$touch()"
  />

  <!-- Street -->
  <ScheppachTextInput
    v-model="props.state.street"
    :margin="3"
    :label="t('form.street')"
    :required="true"
    :input-error="validator.street?.$error"
    :input-error-message="validator.street?.$errors[0]?.$message"
    :placeholder="t('form.streetPlaceholder')"
    autocomplete="billing street-state"
    @blur="validator.street?.$touch()"
  />

  <div class="flex flex-col sm:flex-row gap-4">
    <!-- Postal Code -->
    <ScheppachTextInput
      v-model="props.state.zipcode"
      class="w-full sm:w-1/3"
      :label="t('form.postalCode')"
      :margin="3"
      :required="true"
      :input-error="validator.zipcode?.$error"
      :input-error-message="validator.zipcode?.$errors[0]?.$message"
      :placeholder="t('form.postalCodePlaceholder')"
      autocomplete="billing postal-code"
      @blur="validator.zipcode?.$touch()"
    />

    <!-- City -->
    <ScheppachTextInput
      v-model="props.state.city"
      class="w-full sm:w-2/3"
      :label="t('form.city')"
      :required="true"
      :margin="3"
      :input-error="validator.city?.$error"
      :input-error-message="validator.city?.$errors[0]?.$message"
      :placeholder="t('form.cityPlaceholder')"
      autocomplete="billing state-level2"
      @blur="validator.city?.$touch()"
    />
  </div>

  <!-- Country -->
  <label
    for="selectCountry"
    class="text-scheppach-primary-500 text-sm md:text-base mb--3"
  >
    {{ t("form.country") }} *
  </label>
  <select
    id="selectCountry"
    v-model="props.state.countryId"
    required
    class="sw-search-input placeholder-text-scheppach-neutral-500 text-scheppach-primary-500 bg-scheppach-neutral-50 p-3 outline-none w-full b-b"
    :class="[
      validator.countryId.$error
        ? 'b-scheppach-error-500'
        : 'b-scheppach-primary-700',
    ]"
    @blur="validator.countryId.$touch()"
  >
    <option disabled selected value="">
      {{ t("form.selectCountryPlaceholder") }}
    </option>
    <option
      v-for="country in getCountries"
      :key="country.iso"
      :value="country.id"
    >
      {{ country.name }}
    </option>
  </select>
  <span
    v-if="validator.countryId.$error"
    class="mt--2 text-sm c-scheppach-error-500 focus:ring-primary border-secondary-300"
  >
    {{ validator.countryId.$errors[0]?.$message }}
  </span>

  <!-- Phone -->
  <ScheppachTextInput
    v-model="props.state.phoneNumber"
    class="mt-5"
    :label="t('form.phone')"
    :margin="3"
    :required="!isLoggedIn && !isGuestSession"
    :input-error="validator.phoneNumber?.$error"
    :input-error-message="validator.phoneNumber?.$errors[0]?.$message"
    :placeholder="t('form.phone')"
    autocomplete="phone"
    @blur="validator.phoneNumber?.$touch()"
  />

  <!-- Email -->
  <ScheppachTextInput
    v-if="hasEmail"
    v-model="props.state.email"
    type="email"
    :label="t('form.email')"
    :required="true"
    :input-error="validator.email?.$error"
    :input-error-message="validator.email?.$errors[0]?.$message"
    :placeholder="t('form.emailPlaceholder')"
    autocomplete="email"
    @blur="validator.email?.$touch()"
  />
</template>
